import { Info } from '@hexa-ui/icons';
import React, { ReactNode } from 'react';

interface InlineTooltipProps {
    /**
     * The help text to display next to the icon
     */
    helpText: string;

    /**
     * Custom icon to use instead of the default Info icon
     */
    icon?: ReactNode;

    /**
     * Size of the icon (defaults to medium)
     */
    iconSize?: 'medium' | 'large' | 'tiny' | 'xlarge';

    /**
     * Additional styles to apply to the container
     */
    containerStyle?: React.CSSProperties;

    /**
     * Additional styles to apply to the text
     */
    textStyle?: React.CSSProperties;
}

/**
 * InlineTooltip component displays an icon with inline help text
 * This is a more reliable alternative to popup tooltips that can cause ResizeObserver errors
 */
const InlineTooltip: React.FC<InlineTooltipProps> = ({
    helpText,
    icon,
    iconSize = 'medium',
    containerStyle = {},
    textStyle = {},
}) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '5px',
                position: 'static',
                ...containerStyle
            }}
        >
            <span style={{ cursor: 'help' }}>
                {icon || <Info size={iconSize} />}
            </span>
            <span
                style={{
                    marginLeft: '8px',
                    fontSize: '12px',
                    color: '#666',
                    ...textStyle
                }}
            >
                {helpText}
            </span>
        </div>
    );
};

export default InlineTooltip; 