import { Alert, Badge, Button, Card, Chip, Divider, Grid, Heading, IconButton, Input, LoadingBuzz, Toast, Toggle } from '@hexa-ui/components';

import { Plus } from '@hexa-ui/icons';
import { Form, Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { createWorkflow } from '../../api/approval';
import FormErrorMessage from '../../components/atoms/FormErrorMessage';
import BreadcrumbDefault from '../../components/BreadcrumbDefault';
import ButtonBack from '../../components/ButtonBack';
import InlineTooltip from '../../components/InlineTooltip';

const validationSchema = Yup.object({
    name: Yup.string()
        .required('field cannot be empty'),

    description: Yup.string()
        .min(15)
        .required('field cannot be empty'),

    minimum_approval: Yup.number()
        .min(1)
        .required('field cannot be empty'),

    responsible_team: Yup.string()
        .required('field cannot be empty'),

    webhook: Yup.string()
        .required('field cannot be empty'),

    custom_id: Yup.string()
        .matches(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i, 'Must be a valid UUID'),


});

const CreateWorkflowPage = (): JSX.Element => {
    const navigate = useNavigate();
    const [onLoadingSubmit, setOnLoadingSubmit] = useState(false);
    const [openSuccessToast, setOpenSuccessToast] = useState(false);
    const [openTechnicalErrorToast, setOpenTechnicalErrorToast] = useState(false);
    const [dataRequests, setDataRequests] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [filterStatus, setFilterStatus] = useState('');
    const [filterPipeline, setFilterPipeline] = useState('');
    const [additionalApprovers, setAdditionalApprovers] = useState([]);
    const [additionalApproverEmail, setAdditionalApproverEmail] = useState('');
    const [authParameters, setAuthParameters] = useState([]);
    const [authParameter, setAuthParameter] = useState({
        location: '',
        name: '',
        value: ''
    });
    const [authType, setAuthType] = useState('');
    const [disableInputParameterLocation, setDisableInputParameterLocation] = useState(false);
    const [initialWorkflowValues, setInitialWorkflowValues] = useState({
        name: '',
        description: '',
        minimum_approval: 1,
        responsible_team: '',
        webhook: '',
        max_retries_call_workflow: 5,
        custom_id: ''
    });
    const [errorInputAdditionalApprover, setErrorInputAdditionalApprover] = useState('');
    const [msgTechnicalErrorToast, setMsgTechnicalErrorToast] = useState("We are currently experiencing technical issues. Don't worry, we are working to resolve them.");
    const [counterAdditionalApprovers, setCounterAdditionalApprovers] = useState(0);
    useEffect(() => {

    }, [])

    const handleOnchangeFilterStatus = (e) => {
        setFilterStatus(e)
        var filteredStatus = []

        if (filterPipeline.length > 0) {
            filteredStatus = dataRequests.filter(obj => obj["result"] === e && obj["pipelineId"] == filterPipeline);
        } else {
            filteredStatus = dataRequests.filter(obj => obj["Status"] === e);
        }

        //setSearchFilter(filteredStatus);
    }

    const clearFilters = () => {
        setFilterPipeline('')
        setFilterStatus('')
        //setSearchFilter(dataRequests)
    }

    const handleInputChangeApproverEmail = (event) => {
        const { name, value } = event.target;

        setAdditionalApproverEmail(value);
        setErrorInputAdditionalApprover('');
    };

    const handleAddApprover = () => {
        if (additionalApproverEmail === '') {
            setErrorInputAdditionalApprover('Field cannot be empty');
            return;
        }

        let namePart = additionalApproverEmail.split('@')[0];
        let name = namePart.replace(/[^a-zA-Z]/g, ' ');
        name = name.trim().replace(/\b\w/g, char => char.toUpperCase());

        const newApprovers = [...additionalApprovers, { 'email': additionalApproverEmail, name: name }];
        setAdditionalApprovers(newApprovers);
        setAdditionalApproverEmail('');
        setCounterAdditionalApprovers(counterAdditionalApprovers + 1);
    }

    const handleRemoveAddionalApprover = (approver) => {

        const filteredApprovers = additionalApprovers.filter((a) => a.email !== approver);
        setAdditionalApprovers(filteredApprovers);
        setCounterAdditionalApprovers(counterAdditionalApprovers - 1);
    }


    const handleFormikSubmit = useCallback((values, { setSubmitting, setTouched }) => {
        setTouched({
            name: true,
            description: true,
            minimum_approval: true,
            responsible_team: true,
            webhook: true,
            max_retries_call_workflow: true
        });
        setSubmitting(true);

        debugger;

        const valuesWithConverted = {
            ...values,
            minimum_approval: parseInt(values.minimum_approval, 10),
            max_retries_call_workflow: parseInt(values.max_retries_call_workflow, 10)
        };



        const input = {
            ...valuesWithConverted,
            additional_approvers: additionalApprovers,
        };


        createWorkflow(input)
            .then((r) => {
                if (r.status != 201) {
                    const error = r.data.error;
                    setMsgTechnicalErrorToast(error);
                    setOpenTechnicalErrorToast(true);
                }
                r.data.workflow_id && navigate(`/oneask-portal/workflow/${r.data.workflow_id}?tab=tab2&&new=true`);
            })
            .finally(() => {
                setSubmitting(false);
                setIsLoading(false);
            })

    }, [additionalApprovers])

    return (
        <><Grid.Container
            style={{
                rowGap: '1rem',
                padding: '2rem',
                marginLeft: '5rem',
            }}
            type='fluid'
        >
            <Grid.Item lg={12} md={12} sm={12}>
                <BreadcrumbDefault
                    key={1}
                    config={[
                        {
                            title: "Approval Flow",
                            href: "/oneask-portal/approvalflow",
                            isCurrentPage: false
                        },
                        {
                            title: "Workflow Configurations",
                            href: "",
                            isCurrentPage: false
                        }

                    ]} />
            </Grid.Item>

            <Grid.Item lg={12} md={12} sm={12}>
                <ButtonBack link={'/oneask-portal/approvalflow'} description={'New Workflow Configuration'} />
            </Grid.Item>

            {isLoading ? (
                <Grid.Item xs={12} md={12} sm={12} style={{ flexDirection: 'column' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
                        <LoadingBuzz size='xxlarge' />
                    </div>
                </Grid.Item>
            ) :
                (
                    <>
                        <Grid.Item lg={4} md={4} sm={4}>
                            <Card elevated={'large'} border={'medium'} style={{ width: '100%' }}>
                                <Grid.Container type='fluid'>
                                    <Grid.Item lg={4} md={4} sm={4} style={{ alignItems: 'center', marginTop: 10 }}>
                                        <Heading size='H4'>Approvers</Heading>
                                        <Badge.Counter
                                            counterLabel={counterAdditionalApprovers}
                                            size="large"
                                        />
                                    </Grid.Item>



                                    <Grid.Item sm={12} lg={12} md={12} style={{ marginTop: 10, alignItems: 'center', gap: 10 }} >
                                        <Input
                                            required
                                            name="approver_email"

                                            placeholder="responsible for approval"
                                            errorText="Error Text"
                                            width="100%"
                                            onChange={handleInputChangeApproverEmail}
                                            value={additionalApproverEmail}

                                        />

                                        <IconButton variant='tertiary' icon={Plus} onClick={handleAddApprover}></IconButton>



                                    </Grid.Item>


                                    <Grid.Item sm={12} lg={12} md={12} style={{ marginTop: 10, marginBottom: 10 }}>
                                        {errorInputAdditionalApprover && (
                                            <Alert
                                                style={{ width: '100%' }}
                                                className='text-error-alert'
                                                message={errorInputAdditionalApprover}
                                                type="error"
                                            />
                                        )}
                                    </Grid.Item>

                                </Grid.Container>
                                <Divider />



                                <Grid.Container type='fluid'>

                                    {counterAdditionalApprovers == 0 && (
                                        <Grid.Item sm={12} lg={12} md={12} style={{ marginTop: 10, marginBottom: 10, alignItems: 'center' }}>
                                            <Alert
                                                style={{ width: '100%' }}
                                                className='text-error-alert'
                                                message={'No approvers added'}
                                                type="warning"
                                            />
                                        </Grid.Item>
                                    )}


                                    {additionalApprovers.map(({ email: approver_email }) => (
                                        <Grid.Item sm={3} lg={3} md={3} style={{ marginTop: 10, display: 'flex', justifyContent: 'space-around' }}>
                                            <Chip.Root style={{ marginLeft: 10 }} key={approver_email}>
                                                <Chip.Label onClick={() => { }}>
                                                    {approver_email}
                                                </Chip.Label>
                                                <Chip.DeleteIcon onDelete={() => handleRemoveAddionalApprover(approver_email)}></Chip.DeleteIcon>
                                            </Chip.Root>
                                        </Grid.Item>


                                    ))}


                                </Grid.Container>
                            </Card>
                        </Grid.Item>
                        <Grid.Item lg={8} md={8} sm={8}>
                            <Card elevated={'large'} border={'medium'} >
                                <Formik

                                    initialValues={initialWorkflowValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleFormikSubmit}
                                    enableReinitialize={true}
                                >
                                    {formik => (
                                        <Form onSubmit={formik.handleSubmit}>

                                            <Grid.Container type='fluid' style={{ padding: '1rem' }}>

                                                <Grid.Item md={12} lg={12} sm={12} style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>

                                                    <Toggle style={{ marginRight: '5px' }} />
                                                    <label htmlFor="role" className='label'><b>Send Slack notification</b></label>
                                                </Grid.Item>

                                                <Grid.Item sm={12} lg={4} md={12} style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <Input
                                                        required
                                                        name="name"
                                                        label="Name *"
                                                        placeholder="e.g. Create access in database"
                                                        errorText="Error Text"
                                                        width="100%"
                                                        onBlur={formik.handleChange}
                                                        onChange={formik.handleChange} />
                                                    <FormErrorMessage name="name" />
                                                </Grid.Item>

                                                <Grid.Item sm={12} lg={8} md={12} style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <Input
                                                        required
                                                        name="description"
                                                        label="Description *"
                                                        placeholder="e.g. This workflow creates user in database"
                                                        width="100%"
                                                        onBlur={formik.handleChange}
                                                        onChange={formik.handleChange} />
                                                    <FormErrorMessage name="description" />
                                                </Grid.Item>

                                                <Grid.Item sm={12} lg={3} md={12} style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                                                    <Input
                                                        required
                                                        name="minimum_approval"
                                                        label="Minimum Approval *"
                                                        placeholder="e.g. 1"
                                                        errorText="Error Text"
                                                        width="100%"
                                                        onBlur={formik.handleChange}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.minimum_approval} />
                                                    <FormErrorMessage name="minimum_approval" />
                                                </Grid.Item>
                                                <Grid.Item sm={12} lg={9} md={12} style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                                                    <Input
                                                        required
                                                        name="responsible_team"
                                                        label="Responsible Team *"
                                                        placeholder="e.g. Cloud Team"
                                                        errorText="Error Text"
                                                        width="100%"
                                                        onBlur={formik.handleChange}
                                                        onChange={formik.handleChange} />
                                                    <FormErrorMessage name="responsible_team" />
                                                </Grid.Item>

                                                <Grid.Item sm={12} lg={8} md={12} style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                                                    <Input
                                                        required
                                                        name="webhook"
                                                        label="URL Webhook *"
                                                        placeholder="e.g. http://localhost:8080/workflow/run"
                                                        errorText="Error Text"
                                                        width="100%"
                                                        onBlur={formik.handleChange}
                                                        onChange={formik.handleChange} />

                                                    <FormErrorMessage name="responsible_team" />
                                                </Grid.Item>
                                                <Grid.Item sm={12} lg={4} md={12} style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                                                    <Input
                                                        required
                                                        name="max_retries_call_workflow"
                                                        label="Maximum attempts *"
                                                        placeholder="5"
                                                        errorText="Error Text"
                                                        width="100%"
                                                        onBlur={formik.handleChange}
                                                        onChange={formik.handleChange} />
                                                    <InlineTooltip

                                                        helpText="Maximum number of attempts to call the webhook in case of failures"
                                                    />
                                                    <FormErrorMessage name="responsible_team" />
                                                </Grid.Item>

                                                <Grid.Item sm={12} lg={12} md={12} style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <Input
                                                        required
                                                        name="custom_id"
                                                        label="Custom ID *"
                                                        placeholder="e.g. a828c57e-ac96-4990-bb13-bc4f0fa9038c"
                                                        errorText="Error Text"
                                                        width="100%"
                                                        onBlur={formik.handleChange}
                                                        onChange={formik.handleChange} />
                                                    <InlineTooltip

                                                        helpText="If you want to use your own id, enter the field"
                                                    />
                                                    <FormErrorMessage name="custom_id" />
                                                </Grid.Item>

                                                <Grid.Item sm={12} style={{ justifyContent: 'center' }}>
                                                    <Button

                                                        elevated
                                                        size="medium"
                                                        variant="primary"
                                                        type='submit'
                                                        isLoading={onLoadingSubmit}
                                                        disabled={onLoadingSubmit}
                                                    >
                                                        Create
                                                    </Button>
                                                </Grid.Item>
                                            </Grid.Container>
                                        </Form>
                                    )}
                                </Formik>
                            </Card>
                        </Grid.Item>

                    </>

                )}
        </Grid.Container >
            <Toast.Provider
                duration={5000}
                swipeDirection="right"
            >
                <Toast.Root
                    open={openSuccessToast}
                    onOpenChange={setOpenSuccessToast}
                    message="Your request was successfully made. Redirecting to the your personal requests screen."
                    showCloseButton
                    position="top-right"
                    type="success" />
            </Toast.Provider>
            <Toast.Provider
                duration={1500}
                swipeDirection="right"
            >
                <Toast.Root
                    open={openTechnicalErrorToast}
                    onOpenChange={setOpenTechnicalErrorToast}
                    message={msgTechnicalErrorToast}
                    showCloseButton
                    // actions={[{
                    //     action: redirectManually,
                    //     name: 'Pipeline Link'
                    // }]}
                    position="top-right"
                    type="error"
                />
            </Toast.Provider>
        </>

    );
};

export default CreateWorkflowPage;
