import React from 'react';

export type EnvConfig = {
  name: string;
  ENV: string;
  ENV_TYPE: string;
  OPTIMIZELY_KEY: string;
  BFF_URL: string;
  API_URL: string;
  SUBMIT_URL: string;
  ISSUES_URL: string;
  SUBMIT_OFF_URL: string;
  SERVICE_URL: string;
  FEATURE_TOGGLE_APPROVAL_FLOW: string;
  FEATURE_TOGGLE_WORKFLOW_RUNS: string;
  FEATURE_TOGGLE_REQUESTS: string;

};

const defaultInitialValue: EnvConfig = {
  name: '',
  ENV: '',
  ENV_TYPE: '',
  OPTIMIZELY_KEY: '',
  BFF_URL: '',
  API_URL: '',
  SUBMIT_URL: '',
  ISSUES_URL: '',
  SUBMIT_OFF_URL: '',
  SERVICE_URL: '',
  FEATURE_TOGGLE_APPROVAL_FLOW: '',
  FEATURE_TOGGLE_WORKFLOW_RUNS: '',
  FEATURE_TOGGLE_REQUESTS: ''
};

export const EnvContext = React.createContext(defaultInitialValue);

export const useEnvContext = () => React.useContext(EnvContext);

export const EnvProvider = ({ env, children }: { env: EnvConfig; children: React.ReactNode }) => (
  <EnvContext.Provider value={env}>{children}</EnvContext.Provider>
);
