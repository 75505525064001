import { Breadcrumb } from '@hexa-ui/components';

const BreadcrumbDefault = ({ config }) => {
  return (
    <>
      <div style={{ marginTop: '10px' }}>
        <Breadcrumb.Root>
          <Breadcrumb.HomeItem href='/oneask-portal' asChild>
            <a>Home</a>
          </Breadcrumb.HomeItem>
          {config.map(({ title, href, isCurrentPage }) => (
            <Breadcrumb.Item isCurrentPage={isCurrentPage} href={href} asChild key={title}>
              <a>{title}</a>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb.Root>
      </div>

    </>
  );
};

export default BreadcrumbDefault;
