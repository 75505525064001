import { Heading, IconButton } from '@hexa-ui/components';
import { ArrowLeft } from '@hexa-ui/icons';
import { Link } from 'react-router-dom';

const ButtonBack = ({ link, description }) => {

    return (
        <>
            <Link to={link} >
                <IconButton
                    icon={ArrowLeft}
                    style={{ marginRight: '20px' }}
                />

            </Link>
            <Heading size="H3">{description}</Heading>
        </>
    );
};

export default ButtonBack;

