import { Alert } from "@hexa-ui/components";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";

const AlertError = ({ message, clearError }) => {
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    if (clearError) {
      const timer = setTimeout(() => {
        setFadeOut(true);
        setTimeout(clearError, 1000);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [clearError]);

  return (
    <div className={`alert-error-container ${fadeOut ? 'fadeOut' : ''}`}>
      <Alert
        className='assignee-error-alert'
        message={message}
        type="error"
      />
    </div>
  );
};

const FormErrorMessage = ({ name }): JSX.Element => {
  const [showError, setShowError] = useState(false);
  const { errors, touched } = useFormikContext();

  useEffect(() => {
    if (touched[name] && errors[name]) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [touched, errors, name]);

  const clearError = () => setShowError(false);

  return (
    showError ? <AlertError message={errors[name]} clearError={clearError} /> : null
  );
};

export default FormErrorMessage;
