import httpClient from "./client";

export async function runCreateAccess(data): Promise<void> {
    await httpClient.post('/approval/request', data);
}

export async function listRequests(approverEmail): Promise<any> {
    const { data } = await httpClient.get(`/approval/requests?approverEmail=${approverEmail}`);
    return data;
}

export async function listWorkflow(): Promise<any> {
    const { data } = await httpClient.get('/approval/workflow/list');
    return data;
}

export async function getRequestDetails(requestId, approverEmail): Promise<any> {
    const { data } = await httpClient.get(`/approval/request/${requestId}/details?approverEmail=${approverEmail}`);
    return data;
}

export async function approveRequest(body): Promise<any> {
    const response = await httpClient.post(`/approval/request/approve`, body);
    return response;
}

export async function getRunWorkflowDescribe(workflowId, runId): Promise<any> {
    const { data } = await httpClient.get(`/workflow/${workflowId}/run/describe?runId=${runId}`);
    return data;
}

export async function getWorkflowRuns(): Promise<any[]> {
    const { data } = await httpClient.get(`/workflow/list?workflowType=DummyWorkflow`);

    return data;
}

export async function createWorkflow(data): Promise<any> {
    const response = await httpClient.post(`/workflow`, data);
    return response;
}

export async function updateWorkflow(id, data): Promise<any> {
    const response = await httpClient.put(`/workflow/${id}`, data);
    return response;
}

export async function getWorkflow(id): Promise<any> {
    const { data } = await httpClient.get(`/workflow/${id}`);

    return data;
}

export async function addAdditionalApprover(workflowId, data): Promise<any> {
    const response = await httpClient.patch(`/workflow/${workflowId}/approvers`, data);
    return response;
}

export async function removeAdditionalApprover(workflowId, approverEmail): Promise<any> {
    const response = await httpClient.delete(`/workflow/${workflowId}/approvers/${approverEmail}`);
    return response;
}

export async function createWorkflowAuth(data): Promise<any> {
    const response = await httpClient.post(`/workflow/auth`, data);
    return response;
}

export async function updateWorkflowAuth(id, data): Promise<any> {
    const response = await httpClient.put(`/workflow/auth/${id}`, data);
    return response;
}

export async function getRequestApproval(requesterEmail, status, workflowId): Promise<any> {
    const params = {
        requesterEmail,
        status,
        workflowId
    };
    const { data } = await httpClient.get(`/approval/request-approvals`, { params });

    return data;
}

export async function getApprovals(requestId): Promise<any> {
    const { data } = await httpClient.get(`/approval/request/${requestId}`);
    return data;
}

export async function getRequesterRequestDetails(requestId): Promise<any> {
    const { data } = await httpClient.get(`/approval/request/${requestId}/requester/details`);
    return data;
}
